import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DateTime } from "luxon";

export const TimeDisplay = ({ time, title, format }) => {
    if (!time) return null;
    const value = DateTime.fromISO(time).toFormat(format);
    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6} className="mb-3">
                    <span className="css-time-title-typography">{title}</span>
                    <Container className="css-time-content">
                        <span className="css-time-typography">{value}</span>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};