import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTime } from "luxon";
import TextField from '@mui/material/TextField';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import useFetchWithMsal from '../hooks/useFetchWithMsal';

import { protectedResources } from "../authConfig";


export const AttendanceForm = (props) => {

    const [value, setValue] = useState(DateTime.fromISO(props.currentTime));

    const [label] = useState(props.checkIn ? "Registrar salida" : "Registrar entrada");
    const [labelInfo] = useState(props.checkIn ? "Salida" : "Entrada");
    const [justifications, setJustifications] = useState(null);
    const [automaticMode, setAutomaticMode] = useState(true);
    const [comments, setComments] = useState('');
    const [justification, setJustification] = useState(0);
    const { execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });
    const [validated, setValidated] = useState(false);
    const [errorDate, setErrorDate] = useState(false);
    const [currentError, setCurrentError] = useState(null);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }

        const attendance = {
            automaticMode: automaticMode,
            value: value.toString(),
            justificationId: justification,
            comments: comments
        };

        if (props.attendanceId) {
            props.handleCheckOut(attendance);
        } else {
            props.handleCheckIn(attendance);
        }


    }

    const handleChange = () => {
        setAutomaticMode(!automaticMode);
    };



    const handleCommentsChange = (e) => {
        if (/^[A-Za-z0-9\s]+$/g.test(e.target.value))
            setComments(e.target.value);
        else
            e.target.value = "";
    };

    const handleJustificationChange = (e) => {
        setJustification(e.target.value);
    };



    useEffect(() => {
        if (!justifications) {
            execute("GET", protectedResources.api.justification).then((response) => {
                if (response && response.data) {
                    setJustifications(response.data);
                }
            });
        }
    }, [execute, justifications]);


    useEffect(() => {
        if (automaticMode) {
            execute("GET", protectedResources.api.time).then((response) => {
                if (response && response.data) {
                    setValue(response.data);
                }
            });
        }
        const interval = setInterval(() => {
            if (automaticMode) {
                execute("GET", protectedResources.api.time).then((response) => {
                    if (response && response.data) {
                        setValue(response.data);
                    }
                });
            }
        }, 60000);
        return () => clearInterval(interval);
    }, [execute, automaticMode]);




    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6} >
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <h6 className="tituloH6">{labelInfo}</h6>
                        <Form.Group as={Col} id="formGridCheckbox">
                            <Form.Check type="switch" id="custom-switch" label="Autom&aacute;tico" defaultChecked={automaticMode} onChange={handleChange} className="mb-2" />
                        </Form.Group>
                        <Row className="mb-3 mt-1">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    <TimePicker className="mt-2"
                                        label="Hora"
                                        value={value}
                                        readOnly={automaticMode}
                                        onChange={(value) => {
                                            if (!value) {
                                                setErrorDate(true);
                                                setCurrentError("La hora es un campo requerido");
                                            } else {
                                                setErrorDate(false);
                                                setCurrentError(null);
                                                setValue(value);
                                            }
                                        }}
                                        onError={(reason, value) => {
                                            if (value) {
                                                if (reason) {
                                                    setCurrentError(reason);
                                                    setErrorDate(true);
                                                } else {
                                                    setErrorDate(false);
                                                    setCurrentError(null);
                                                }
                                            } else {
                                                setErrorDate(true);
                                                setCurrentError("La hora es un campo requerido");
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                error={errorDate}
                                                helperText={currentError ?? currentError}
                                            />
                                        )}

                                    />
                                </LocalizationProvider>
                            </Form.Group>
                        </Row>
                        {!automaticMode && <><Form.Group className="mb-3" controlId="formGridAddress2">
                            <FloatingLabel controlId="floatingSelect" label="Justificaci&oacute;n">
                                <Form.Select aria-label="Floating label select example" onChange={handleJustificationChange} required>
                                    <option value="">Selecione una justificaci&oacute;n</option>
                                    {justifications.map((value, index) => <option key={index} value={value.justificationId}>{value.name}</option>
                                    )}
                                </Form.Select>
                            </FloatingLabel>
                        </Form.Group><Form.Group className="mb-3" controlId="formGridAddress3">
                                <FloatingLabel controlId="floatingTextarea2" label="Comentarios">
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Leave a comment here"
                                        onChange={handleCommentsChange}
                                        style={{ height: '100px' }}
                                    />
                                </FloatingLabel>
                            </Form.Group></>}

                        <Form.Group>
                            <Button variant="bs-yellow" type="submit" size="lg" style={{ background: '#ffda00' }} className="mb-3">{label}</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container >
    );
}
