import React from 'react';
import Container from 'react-bootstrap/Container';
import { Button } from '@mui/material';

export const WelcomeMessagge = (props) => {

    const handleFlag = () => {
        props.handleFlag(true);
    }
    return (
        <Container>
            <h4 className='tituloH4'>¡Excelente día!</h4>
            <p className='texto'>Tu hora de entrada se registró correctamente</p>
            <p>Una vez finalizada tu jornada de trabajo vuelve a ingresar para registrar tu hora de salida</p>
            <Button variant="bs-yellow" type="submit" size="lg" style={{background:'#ffda00'}} className="mb-3" onClick={handleFlag}>Registrar Salida</Button>
        </Container>
    );
};