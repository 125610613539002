import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest, protectedResources } from "../authConfig";

import React, { useEffect, useState } from 'react';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { AttendanceView } from '../components/AttendanceView';
import LoadingSpinner from "../components/LoadingSpinner";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const customId = "custom-id-yes";


const AttendanceContent = () => {
    const { isLoading, error, execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });

    const [data, setData] = useState(null);




    useEffect(() => {
        if (!data) {
            execute("GET", protectedResources.api.attendance).then((response) => {
                if (response && response.data) {
                    setData(response.data);
                }
            }).catch((error) => {
                console.log(error);


            });
        }
    }, [execute, data])



    if (error) {
        toast.error(error.message, {
            toastId: customId,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    return <>{isLoading ? <LoadingSpinner /> : data ? <AttendanceView data={data} /> : null}</>;
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const Attendance = () => {
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
            <AttendanceContent />
        </MsalAuthenticationTemplate >
    );
};
