import React from "react";
import Container from "react-bootstrap/esm/Container";

export const FarewellMessagge = () => {

    return(
        <Container>
            <h4 className="tituloH4">¡Hasta mañana!</h4>
            <p>Tu hora de salida se registró correctamente</p>
        </Container>
    );
};