import { DateTime } from "luxon";
import React from "react";
import Container from 'react-bootstrap/Container';
import { TimeDisplay } from "./TimeDisplay";


export const TimeView = (props) => {
    return (
        <Container>
            <TimeDisplay time={props.checkIn ? props.checkIn : props.currentTime} title={"Fecha"} format={"dd/MM/yyyy"} />
            <TimeDisplay time={props.checkIn} title={"Entrada"} format={"hh:mm a"} />
            <TimeDisplay time={props.checkOut} title={"Salida"} format={"hh:mm a"} />
        </Container>
    );
}
